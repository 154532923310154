import React, { Component } from "react";
import Fade from "react-reveal";

class Technology extends Component {
  render() {
    return (
      <section id="portfolio">
        <Fade left duration={1000} distance="40px">
          <div className="row">
            <h1>The powerful tools we use</h1>
            <div className="wrapper">
              <Fade bottom duration={1400}>
                <img className="tools" src="./images/vscode.png"></img>
              </Fade>
              <Fade bottom duration={1400}>
                <img className="tools" src="./images/azure.png"></img>
              </Fade>
              <Fade bottom duration={1400}>
                <img className="tools" src="./images/net.png"></img>
              </Fade>
              <Fade bottom duration={1400}>
                <img className="tools" src="./images/nodejs.png"></img>
              </Fade>
              <Fade bottom duration={1400}>
                <img className="tools" src="./images/react.png"></img>
              </Fade>
              <Fade bottom duration={1400}>
                <img className="tools" src="./images/cosmos.png"></img>
              </Fade>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default Technology;
