import React from "react";
import Fade from "react-reveal";

function ImgOverlayExample() {
  return (
    <section id="resume">
      <div className="samples">
        <Fade left duration={1000} distance="40px">
          <h4>recent working samples</h4>
          <div className="container">
            <a href="https://www.energonepe.gr/" target="_blank">
              <img
                src="../images/energonsite.png"
                loading="lazy"
                alt=" "
                className="website"
              />
              <p class="caption">Energon Ltd. Corporate website.</p>
            </a>
          </div>
        </Fade>
      </div>
    </section>
  );
}

export default ImgOverlayExample;
