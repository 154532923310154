import React, { useState } from "react";

function CookieAcceptance() {
  const [accepted, setAccepted] = useState(false);

  function handleAccept() {
    setAccepted(true);
    // you can also set a cookie here to store the user's preference
  }

  if (accepted) {
    return null;
  }

  return (
    <div>
      <p>
        Our website uses cookies to improve your experience. By continuing to
        browse the site, you are agreeing to our use of cookies.
      </p>
      <button onClick={handleAccept}>Accept</button>
    </div>
  );
}

export default CookieAcceptance;
